/* Custom */
@use "assets/custom/ag-grid" as ag-grid;
@use "assets/custom/primeng" as primeng-custom;

/* PrimeNG */
@use "../node_modules/primeng/resources/primeng.min.css" as primeng-min;
@use "../node_modules/primeicons/primeicons.css" as primeicons;

/* Layout */
@use "assets/layout/styles/layout/layout.css" as layout;
@use "assets/layout/styles/layout/preloading.css" as preloading;

@use "../node_modules/primeflex/primeflex.css";
@import "assets/layout/styles/theme/theme-light/purple/theme.css";

/* Quill Editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/quill-mention/dist/quill.mention.css";

:root {
  --not-editable: #efefef;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--primary-color);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--primary-color);
}

.code-editor {
  height: 100%;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0.75rem 0.75rem;
}

.ag-floating-top::-webkit-scrollbar {
  opacity: 0;
}

.ag-floating-top::-webkit-scrollbar-track {
  opacity: 0;
}

.ag-floating-top::-webkit-scrollbar-thumb {
  opacity: 0;
}

/* Для Firefox */
.ag-floating-top {
  scrollbar-color: transparent transparent;
}
