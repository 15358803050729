.not-editable {
  background-color: var(--not-editable);
}
.ag-theme-quartz .ag-header-group-cell {
  font-weight: 600;
  font-size: 22px;
}
.ag-theme-quartz {
  --ag-header-background-color: var(--purple-100);
  --ag-cell-horizontal-border: solid var(--ag-border-color);
}
